<template>
  <m-card class="flex flex-col mb-4">
    <div class="lg:px-3">
      <div
        class="
          flex
          justify-between
          cursor-default
          pt-3
          bg-white
          dark:bg-base5
          lg:pt-0 lg:sticky
          top-8
          lg:top-0
          z-30
          lg:z-20
        "
      >
        <!-- Meeting title header -->
        <div class="flex items-center min-w-0">
          <div
            v-if="activeMeetingEvent && !loadingGoogleEvent.loading"
            class="lg:flex min-w-0 mr-4 lg:mr-0"
            data-recording-sensitive
          >
            <h3
              :key="`active-meeting-title-${titleUpdated}`"
              class="
                text-grey1
                font-normal
                text-xl
                overflow-hidden
                whitespace-nowrap
                overflow-ellipsis
              "
              :contentEditable="isTitleEditable"
              @click="onTitleClick"
              @keydown.enter.esc.prevent="onTitleEnterEsc"
              @blur="onTitleBlur"
              @input="titlePendingChange = true"
              id="active-meeting-title"
            >
              {{ activeMeetingEvent.summary || '(No title)' }}
            </h3>
            <div class="hidden lg:flex mt-0 lg:mt-1">
              <MCIDate
                :startDateTime="getDateOrDateTime(activeMeetingEvent.start)"
                :endDateTime="getDateOrDateTime(activeMeetingEvent.end)"
                timeOnly
              ></MCIDate>
            </div>
          </div>
          <div v-else>
            <h3
              class="flex font-normal text-xl"
              v-if="!loadingGoogleEvent.errorText"
            >
              &#8203;
              <m-placeholder
                class="mt-1 h-5 rounded"
                style="width: 10rem"
              ></m-placeholder>
              <m-placeholder
                class="mt-1 hidden lg:flex ml-3 h-5 rounded"
                style="width: 7rem"
              ></m-placeholder>
            </h3>
          </div>
          <MCINotes
            class="hidden lg:flex"
            v-if="
              (!isPrivate && editorHasNotes) ||
              (isPrivate && editorHasNotesPrivate)
            "
            @click="onOnlyNotesClick"
            v-bind:isActive="!showOnlyActions"
            showLabel
          ></MCINotes>
          <MCIActions
            class="hidden lg:flex"
            v-if="
              (!isPrivate && editorActionsTotal > 0) ||
              (isPrivate && editorActionsTotalPrivate > 0)
            "
            v-bind:isActive="showOnlyActions"
            captionExpanded
            v-bind:actionsTotal="
              isPrivate ? editorActionsTotalPrivate : editorActionsTotal
            "
            v-bind:actionsCompleted="
              isPrivate ? editorActionsCompletedPrivate : editorActionsCompleted
            "
            @click="onOnlyActionsClick"
            showLabel
          ></MCIActions>
          <!-- any icons, decide here if show or not -->
        </div>
        <div
          class="hidden lg:flex mt-1"
          v-if="activeMeetingEvent && !loadingGoogleEvent.loading"
        >
          <div class="flex">
            <!-- this is need to set correct height when no bubble is displayed -->
            <!-- such as toggle between guest/private when no guests -->
            <div style="font-size: 0.8rem; height: 1.75rem"></div>
            <div class="relative">
              <div>
                <m-location
                  class="mr-3"
                  :location="expandedLocation"
                  @openlocation="showLocationModal($event)"
                  segmentName="Video link clicked"
                >
                </m-location>
              </div>
            </div>
          </div>

          <meeting-guests
            @shareUrl="openRecapModal('url')"
            :guests="meetingAttendeesWithOnlineStatus"
            v-bind:showOnlineStatus="true"
            v-bind:showDetails="true"
          ></meeting-guests>
        </div>
        <div v-if="loadingGoogleEvent.loading" class="hidden lg:flex mt-1">
          <div v-for="i in 4" :key="i">
            <m-placeholder class="ml-1 w-7 h-7 rounded-full"></m-placeholder>
          </div>
        </div>
      </div>

      <div
        v-show="editorLoaded"
        class="flex flex-grow flex-col z-10 activeMeetingEditor"
      >
        <template v-if="activeMeetingEvent && editorACL">
          <!-- TODO make the "not enabled" stuff clearer.. e.g. @toggleEnabled="!enabled" -->
          <notes-editor
            v-show="!isPrivate"
            :isPrivate="false"
            :areThereRelatedMeetings="areThereRelatedMeetings"
            :isPublicRead="isEventPublicRead"
            class="w-full text-grey1 biggerEditor"
            :class="{ mobile: isMobile }"
            :meetingId="activeMeetingEvent.id"
            :nextMeetingDate="nextMeetingDate"
            :calendarId="calendarId"
            :summary="activeMeetingEvent.summary"
            :guests="meetingAttendees"
            :user="user"
            :showOnlyActions="showOnlyActions"
            :readOnly="editorReadOnly"
            @updated="onEditorUpdate"
            @loaded="editorLoaded = true"
            @editorCount="onEditorCount"
            @participants="updateParticipants($event)"
            @tokenExpired="$emit('refreshToken')"
            :addHTMLToNotes="addHTMLToNotesPublic"
            :insertHTML="htmlToInsert"
            :getNote="getNotePublic"
            @noteHTML="onNoteHTMLPublic"
          >
            <template
              v-if="!editorReadOnly && userIsGuest && isAuthenticated"
              v-slot:quickstart
            >
              <quick-start-menu
                v-if="quickMenuLoaded"
                @insert="onQuickMenuClick"
                :calendarDesc="quickStartShowCalendar"
                :lastAgenda="quickStartShowAgenda"
                :lastFull="quickStartShowFull"
              />
              <div v-else>
                <m-placeholder
                  class="h-4 mt-1 lg:mt-0 rounded-lg my-auto"
                ></m-placeholder>
                <m-placeholder
                  class="h-4 mt-4 rounded-lg my-auto"
                ></m-placeholder>
                <m-placeholder
                  class="h-4 mt-4 rounded-lg my-auto"
                ></m-placeholder>
              </div>
            </template>

            <template>
              <!-- Meeting buttons: agenda, icebreaker, assistant -->
              <div class="flex flex-grow justify-end">
                <div
                  class="
                    fixed
                    lg:static
                    -right-20
                    -mr-2
                    lg:mr-0 lg:flex lg:mt-1
                    z-50
                    lg:z-30
                  "
                  v-if="!editorReadOnly && userIsGuest && isAuthenticated"
                >
                  <transition name="fade">
                    <div
                      class="relative"
                      v-show="
                        hasRelatedIncompleteActions[
                          isPrivate ? 'private' : 'public'
                        ]
                      "
                    >
                      <m-button
                        class="mr-2 mb-2 lg:mb-0"
                        button-style="btn-tertiary"
                        @click="onOpenLeftovers"
                        segmentName="Leftovers opened"
                        :segmentProps="{ isPrivate: isPrivate }"
                        title=""
                        icon="✅"
                        >Leftovers
                      </m-button>
                    </div>
                  </transition>
                  <div class="relative">
                    <m-button
                      class="mr-2 mb-2 lg:mb-0"
                      button-style="btn-tertiary"
                      @click="openAgenda = !openAgenda"
                      segmentName="Agenda opened"
                      :segmentProps="{ isPrivate: isPrivate }"
                      title="Add text templates to your note"
                      icon="📑"
                      >Templates
                    </m-button>
                  </div>
                </div>
                <div
                  id="shared-private-menu"
                  class="
                    fixed
                    bottom-4
                    right-8
                    lg:static lg:flex lg:mt-1 lg:ml-2
                    hide-on-scroll
                    z-30
                  "
                  v-if="!isPrivateReadonly"
                >
                  <m-shared-private-toggle
                    :value="isPrivate"
                    :readonly="isPrivateReadonly"
                    :hasShared="editorHasNotes || editorActionsTotal > 0"
                    :hasPrivate="
                      editorHasNotesPrivate || editorActionsTotalPrivate > 0
                    "
                    :isMeetingPublicRead="isEventPublicRead"
                    v-on:input="isPrivateToggle($event)"
                  />
                </div>
              </div>
            </template>
          </notes-editor>

          <!-- private editor -->
          <template v-if="!isPrivateReadonly">
            <notes-editor
              v-show="isPrivate"
              :isPrivate="true"
              :isPublicRead="isEventPublicRead"
              class="w-full text-grey1 biggerEditor"
              :class="{ mobile: isMobile }"
              :meetingId="activeMeetingEvent.id"
              :nextMeetingDate="nextMeetingDate"
              :calendarId="calendarId"
              :summary="activeMeetingEvent.summary"
              :guests="myselfOnlyAttendee"
              :user="user"
              :showOnlyActions="showOnlyActions"
              :readOnly="editorReadOnly"
              @updated="onEditorUpdate"
              @loaded="editorLoaded = true"
              @editorCount="onEditorCountPrivate"
              @tokenExpired="$emit('refreshToken')"
              :addHTMLToNotes="addHTMLToNotesPrivate"
              :insertHTML="htmlToInsert"
              :getNote="getNotePrivate"
              @noteHTML="onNoteHTMLPrivate"
            >
              <template
                v-if="!editorReadOnly && userIsGuest && isAuthenticated"
                v-slot:quickstart
              >
                <quick-start-menu
                  v-if="quickMenuLoaded"
                  @insert="onQuickMenuClick"
                  :calendarDesc="quickStartShowCalendar"
                  :lastAgenda="quickStartShowAgenda"
                  :lastFull="quickStartShowFull"
                />
                <div v-else>
                  <m-placeholder
                    class="h-4 mt-1 lg:mt-0 rounded-lg my-auto"
                  ></m-placeholder>
                  <m-placeholder
                    class="h-4 mt-4 rounded-lg my-auto"
                  ></m-placeholder>
                  <m-placeholder
                    class="h-4 mt-4 rounded-lg my-auto"
                  ></m-placeholder>
                </div>
              </template>
              <template>
                <!-- Meeting buttons: agenda, icebreaker, assistant -->
                <div class="flex flex-grow justify-end">
                  <div
                    class="
                      fixed
                      lg:static
                      -right-20
                      -mr-2
                      lg:mr-0 lg:flex lg:mt-1
                      z-50
                    "
                    style="top: 10.6rem"
                    v-if="!editorReadOnly && userIsGuest && isAuthenticated"
                  >
                    <transition name="fade">
                      <div
                        class="relative"
                        v-show="
                          hasRelatedIncompleteActions[
                            isPrivate ? 'private' : 'public'
                          ]
                        "
                      >
                        <m-button
                          class="mr-2 mb-2 lg:mb-0"
                          button-style="btn-tertiary"
                          @click="onOpenLeftovers"
                          segmentName="Leftovers opened"
                          :segmentProps="{ isPrivate: isPrivate }"
                          title=""
                          icon="✅"
                          >Leftovers
                        </m-button>
                      </div>
                    </transition>
                    <div class="relative">
                      <m-button
                        class="mr-2 mb-2 lg:mb-0"
                        button-style="btn-tertiary"
                        @click="openAgenda = !openAgenda"
                        segmentName="Agenda opened"
                        title="Add text templates to your note"
                        icon="📑"
                        >Templates
                      </m-button>
                    </div>
                  </div>
                  <div
                    id="shared-private-menu"
                    class="
                      fixed
                      bottom-4
                      right-8
                      lg:static lg:flex lg:mt-1 lg:ml-2
                      hide-on-scroll
                      z-30
                    "
                  >
                    <m-shared-private-toggle
                      :value="isPrivate"
                      :readonly="isPrivateReadonly"
                      :hasShared="editorHasNotes || editorActionsTotal > 0"
                      :hasPrivate="
                        editorHasNotesPrivate || editorActionsTotalPrivate > 0
                      "
                      :isMeetingPublicRead="isEventPublicRead"
                      v-on:input="isPrivateToggle($event)"
                    />
                  </div>
                </div>
              </template>
            </notes-editor>
          </template>
        </template>
      </div>

      <template v-if="!editorLoaded">
        <div class="biggerEditor" :class="{ mobile: isMobile }">
          <div class="sticky top-10 lg:top-8 z-20 lg:z-10">
            <div class="h-0.5 lg:h-1 bg-white dark:bg-base5"></div>
            <div
              class="flex justify-between bg-white dark:bg-base5 w-full mb-0"
            >
              <div class="flex pt-1 lg:pt-0 mt-2 mb-1">
                <m-placeholder
                  class="h-6 rounded-lg my-auto"
                  style="width: 20rem"
                ></m-placeholder>
              </div>
              <div class="hidden lg:flex">
                <m-placeholder
                  class="h-8 rounded-lg my-auto w-28 mr-2"
                ></m-placeholder>
                <m-placeholder
                  class="h-8 rounded-lg my-auto w-28 mr-2"
                ></m-placeholder>

                <m-placeholder
                  class="h-8 rounded-lg my-auto"
                  style="width: 11rem"
                ></m-placeholder>
              </div>
            </div>
            <div class="h-2 lg:h-0 bg-white dark:bg-base5"></div>
            <div class="h-1.5 lg:h-1.5 bottom-fade"></div>
          </div>
          <div class="py-3 pt-2">
            <m-placeholder
              class="h-4 mt-0 lg:mt-1 rounded-lg my-auto"
            ></m-placeholder>
            <m-placeholder
              class="h-4 rounded-lg my-auto quickmenu-loader-first"
            ></m-placeholder>
            <m-placeholder class="h-4 mt-4 rounded-lg my-auto"></m-placeholder>
            <m-placeholder class="h-4 mt-4 rounded-lg my-auto"></m-placeholder>
          </div>
        </div>
      </template>

      <div class="items-center z-20">
        <template
          v-if="
            activeMeetingEvent && !loadingGoogleEvent.loading && editorLoaded
          "
        >
          <m-button
            v-if="
              isAuthenticated && !editorReadOnly && userIsGuest && !isPrivate
            "
            class="self-end mb-3 mr-3 lg:m-3 lg:ml-0"
            :class="{ 'bg-base3': isPrivate }"
            button-style="btn-primary"
            @click="openRecapModal('url')"
            title="Share meeting url with others"
            segmentName="Open send url modal"
            :segmentProps="{ isPrivate: isPrivate }"
            icon="🔗"
          >
            Share URL
          </m-button>
          <m-button
            v-if="isAuthenticated && !editorReadOnly && userIsGuest"
            class="self-end mb-3 mr-3 lg:m-3 lg:ml-0"
            :class="{ 'bg-base3': isPrivate }"
            button-style="btn-primary"
            @click="openRecapModal('note')"
            title="Share meeting notes with others"
            segmentName="Open send note modal"
            :segmentProps="{ isPrivate: isPrivate }"
            icon="✉️"
          >
            Send notes
          </m-button>
          <m-button
            v-if="
              isAuthenticated &&
              !editorReadOnly &&
              userIsGuest &&
              nextRecurringMeeting &&
              false
            "
            class="self-end mb-3 mr-3 lg:m-3 lg:ml-0"
            :class="{ 'bg-base3': isPrivate }"
            button-style="btn-primary"
            @click="openRecapModal('remind')"
            title="Set reminder for meeting"
            segmentName="Open send reminder modal"
            :segmentProps="{ isPrivate: isPrivate }"
            icon="📅"
          >
            Prep next meeting
          </m-button>
          <div v-else></div> </template
        ><template v-else>
          <m-placeholder
            class="h-9 lg:h-10 rounded-lg self-end mb-3 mr-3 lg:m-3 lg:ml-0"
            style="width: 6.5rem"
          ></m-placeholder>
        </template>
      </div>
    </div>

    <div
      class="fixed bottom-14 lg:bottom-4 right-8 hide-on-scroll z-40"
      id="meeting-timer"
    >
      <timer
        @isOver="isMeetingOver($event)"
        v-if="
          activeMeetingEvent &&
          activeMeetingEvent.start &&
          activeMeetingEvent.start.dateTime &&
          !meetingOverStatus
        "
        :start="activeMeetingEvent.start.dateTime"
        :end="activeMeetingEvent.end.dateTime"
      ></timer>
    </div>

    <!-- Modals -->
    <div class="z-40">
      <div v-if="activeMeetingEvent && !loadingGoogleEvent.loading">
        <div v-if="isAuthenticated">
          <end-meeting-modal
            v-bind:show="openEndMeetingModalPublic"
            :title="modalTitle"
            :eventInfo="activeMeetingEvent"
            :nextEventInfo="nextRecurringMeeting"
            :isThereReminderForNextMeeting="isThereReminderForNextMeeting"
            :isThereAutoCancelForNextMeeting="isThereAutoCancelForNextMeeting"
            :calendarId="calendarId"
            :isPrivate="false"
            :userEmail="getMyEmail"
            :modalType="modalType"
            @close="openEndMeetingModalPublic = false"
            v-on:fetchreward="fetchReward++"
            @sent="recapSent($event)"
            @publicaccess="isPubliclyShared = $event"
            :noteHTML="noteHTMLPublic"
            :agendaItemHTML="agendaItemsHTMLPublic"
          ></end-meeting-modal>

          <end-meeting-modal
            v-bind:show="openEndMeetingModalPrivate"
            :title="modalTitle"
            :eventInfo="activeMeetingEvent"
            :nextEventInfo="nextRecurringMeeting"
            :isThereAutoCancelForNextMeeting="isThereAutoCancelForNextMeeting"
            :isThereReminderForNextMeeting="isThereReminderForNextMeeting"
            :calendarId="calendarId"
            :isPrivate="true"
            :userEmail="getMyEmail"
            :modalType="modalType"
            @close="openEndMeetingModalPrivate = false"
            v-on:fetchreward="fetchReward++"
            @sent="recapSent($event)"
            @publicaccess="isPubliclyShared = $event"
            :noteHTML="noteHTMLPrivate"
            :agendaItemHTML="agendaItemsHTMLPrivate"
          ></end-meeting-modal>

          <leftovers-modal
            v-bind:show="openLeftovers"
            title="Leftovers"
            @close="openLeftovers = false"
            :actions="incompleteActions"
            @insertActions="onInsertActions"
          >
          </leftovers-modal>
        </div>
        <m-modal
          v-bind:show="openLoc"
          @close="openLoc = false"
          title="Location"
        >
          <div class="break-words text-grey1 mx-7" data-recording-sensitive>
            <div v-html="locationText" data-recording-sensitive></div>
          </div>
          <template v-slot:bottom_modal>
            <div class="flex w-full justify-end">
              <m-button
                icon="🚪"
                button-style="btn-tertiary"
                @click="openLoc = false"
              >
                Close
              </m-button>
            </div>
          </template>
        </m-modal>

        <template v-if="!editorReadOnly && userIsGuest && isAuthenticated">
          <snippet-modal
            v-bind:show="openAgenda"
            title="Templates &amp; icebreakers"
            @addagendatonotes="onAgendaToNotes($event)"
            :html="getAgendaInHTML"
            @close="openAgenda = false"
            :autoInsertCounter="autoInserCounter"
            :autoInsertUuid="autoInsertId"
          >
          </snippet-modal>
        </template>
      </div>
    </div>

    <m-early-finish-overlay
      v-if="activeMeetingEvent"
      style="z-index: 199"
      :show="showReward"
      :minutesEarly="rewardMinutesEarly"
      :meetingId="activeMeetingEvent.id"
      :fetch="fetchReward"
      @close="showReward = false"
    />
  </m-card>
</template>

<script>
import SnippetModal from '@/components/SnippetModal';
import LeftoversModal from '@/components/LeftoversModal';
import MeetingGuests from '@/components/MeetingGuest/MeetingGuests';
import MLocation from '@/components/UI/MLocation';
import NotesEditor from '@/components/Editor2';
import MButton from '@/components/UI/MButton';
import MModal from '@/components/UI/MModal';
import MCard from '@/components/UI/MCard';
import Timer from './Timer';

import EndMeetingModal from './EndMeetingModal';
import MCIActions from '@/components/UI/MeetingCardIcons/Actions';
import MCINotes from '@/components/UI/MeetingCardIcons/Notes';
import MCIDate from '@/components/UI/MeetingCardIcons/Date';
import MSharedPrivateToggle from '@/components/UI/Wrappers/MSharedPrivateToggle';
import MEarlyFinishOverlay from '@/components/UI/Wrappers/MEarlyFinishOverlay';
import MPlaceholder from '@/components/UI/MPlaceholder';

import { linkify } from '@/components/Utils';
import party from 'party-js';
import { getMeetingAttendees } from '@/components/Utils';
import QuickStartMenu, {
  ItemType,
} from '@/components/Editor2/Menu/Quickstart/Menu.vue';

import {
  patchMeetingTitle,
  ACCESS_LEVEL,
  ACCESS_TYPE,
  checkMeetingAccess,
} from '@/api/Cherry';

export default {
  name: 'ActiveMeeting',
  components: {
    SnippetModal,
    LeftoversModal,
    MeetingGuests,
    MLocation,
    NotesEditor,
    MButton,
    MModal,
    MCard,
    QuickStartMenu,
    EndMeetingModal,
    Timer,
    MCIDate,
    MCIActions,
    MCINotes,
    MPlaceholder,

    MSharedPrivateToggle,
    MEarlyFinishOverlay,
  },
  props: {
    activeMeetingEvent: {
      required: true,
    },
    nextMeetingDate: {
      type: String,
      required: false,
      default: '',
    },
    nextRecurringMeeting: {
      type: Object,
      required: false,
      default: null,
    },
    isThereReminderForNextMeeting: {
      type: Boolean,
      required: false,
      default: false,
    },
    isThereAutoCancelForNextMeeting: {
      type: Boolean,
      required: false,
      default: false,
    },
    calendarId: {
      type: String,
      required: true,
    },
    meetingId: {
      type: String,
      required: true,
    },

    lastMeetingNotesPrivate: {
      type: Object,
      required: false,
      default: null,
    },
    lastMeetingNotesPublic: {
      type: Object,
      required: false,
      default: null,
    },

    loadingGoogleEvent: {
      type: Object,
      required: true,
    },
    expandEditorOnLoad: {
      type: Boolean,
      default: false,
      required: false,
    },
    isPrivate: {
      type: Boolean,
      default: false,
      required: false,
    },
    areThereRelatedMeetings: {
      type: Boolean,
      default: false,
      required: false,
    },
    isPrivateReadonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    userIsGuest: {
      type: Boolean,
      default: false,
      required: false,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
    titleUpdated: {
      type: Number,
      default: 0,
    },
    hasRelatedIncompleteActions: {
      type: Object,
      required: false,
      default: () => ({ private: false, public: false }),
    },
    incompleteActions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      editorHasNotes: false, // does editor contain any notes
      editorActionsTotal: 0, // total number of actions
      editorActionsCompleted: 0, // just completed
      editorHasNotesPrivate: false, // does editor contain any notes
      editorActionsTotalPrivate: 0, // total number of actions
      editorActionsCompletedPrivate: 0, // just completed
      showOnlyActions: false, // if true, filters out everything else then actions in editor
      editorReadOnly: true, //
      openAgenda: false,
      openLeftovers: false,
      leftoversLoading: false,
      openLoc: false,
      openIcebreaker: false,
      openEndMeetingModalPublic: false,
      openEndMeetingModalPrivate: false,
      recapToggle: false,
      isPubliclyShared: false,
      expandEditor: true,
      editorLoaded: false,
      addHTMLToNotesPublic: 0, // counter to insert agenda to notes, counter to detect press
      addHTMLToNotesPrivate: 0, // counter to insert agenda to notes, counter to detect press
      locationText: '',
      getNotePublic: 0,
      getNotePrivate: 0,
      noteHTMLPublic: '',
      noteHTMLPrivate: '',
      agendaItemsHTMLPublic: '',
      agendaItemsHTMLPrivate: '',
      onlineParticipants: [],
      htmlToInsert: '',
      meetingOverStatus: false,
      // isPrivate: false, // editor public or private
      scrollTimer: null,
      // mobileBreakpoint: 0,
      // windowInnerWidth: 0,
      titlePendingChange: false,
      showReward: false, // display giphy reward
      rewardMinutesEarly: 0, // how many minutes user finished early
      fetchReward: 0, // counter to fetch reward before showing
      isEditorSaving: false,
      editorSavingLabel: '',
      editorACL: null,
      remainingBarShown: false,
      autoInsertId: '', // what ID we want to insert from quick menu
      autoInserCounter: 0, // trigger actual insert
      modalType: '',
      modalTitle: 'Share & more',
    };
  },

  watch: {
    meetingId: {
      // immediate: true,
      handler() {
        this.checkAccess();
      },
    },
    calendarId: {
      // immediate: true,
      handler() {
        this.checkAccess();
      },
    },
    activeMeetingEvent: {
      immediate: true,
      handler(newVal) {
        this.editorLoaded = false;

        if (!newVal) return;
        // when we have meetingID

        this.changeEditorEnabled(this.editorACL);
      },
    },
    modalType: {
      handler(newVal) {
        if (newVal === 'note') {
          this.modalTitle = 'Send the notes';
        } else if (newVal === 'remind') {
          this.modalTitle = 'Prepare the next meeting';
        } else if (newVal === 'url') {
          this.modalTitle = 'Share the page';
        }
      },
    },
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    if (this.isTitleEditable && this.titlePendingChange) {
      const newText =
        document
          .getElementById('active-meeting-title')
          ?.innerText?.trim()
          .replace(/\n|\r/g, '') || '✍️';

      patchMeetingTitle(this.calendarId, this.activeMeetingEvent.id, {
        summary: newText,
      });
    }

    window.removeEventListener('scroll', this.onScroll);
  },

  computed: {
    quickMenuLoaded() {
      return (
        this.quickStartShowAgenda !== null &&
        this.quickStartShowFull !== null &&
        this.quickStartShowCalendar !== null
      );
    },
    // quick menu, show agenda option
    quickStartShowAgenda() {
      if (this.isPrivate) {
        if (this.lastMeetingNotesPrivate === null) return null;
        return this.lastMeetingNotesPrivate?.agenda_items != '';
      }

      if (this.lastMeetingNotesPublic === null) return null;
      return this.lastMeetingNotesPublic?.agenda_items != '';
    },
    // quick menu, show full notes option
    quickStartShowFull() {
      if (this.isPrivate) {
        if (this.lastMeetingNotesPrivate === null) return null;
        return !(
          this.lastMeetingNotesPrivate?.notes ==
            '<p data-recording-sensitive=""></p>' ||
          this.lastMeetingNotesPrivate?.notes == ''
        );
      }

      if (this.lastMeetingNotesPublic === null) return null;
      return !(
        this.lastMeetingNotesPublic?.notes ==
          '<p data-recording-sensitive=""></p>' ||
        this.lastMeetingNotesPublic?.notes == ''
      );
    },
    // quick menu, show calendar description option
    quickStartShowCalendar() {
      if (this.activeMeetingEvent === null) {
        return null;
      }
      return !!this.activeMeetingEvent?.description?.length;
    },
    isTitleEditable() {
      return this.activeMeetingEvent?.creator?.email == 'notes@meetric.app';
    },
    isEventPublicRead() {
      return this.$store?.getters['getActiveEventPublicRead'];
    },
    isMobile() {
      return this.$store?.getters['isMobile'];
    },
    meetingAttendeesWithOnlineStatus() {
      let attendees = this.meetingAttendees || [];
      const withOnline = attendees.map((a) => {
        a.online = this.onlineParticipants.includes(a.email);
        return a;
      });

      return withOnline;
    },
    meetingAttendees() {
      return getMeetingAttendees(this.activeMeetingEvent);
    },
    getAgendaInHTML() {
      if (this.activeMeetingEvent.description) {
        // in plaintext, replace newline with html tag and remove leading new lines

        let htmlNewLines = this.activeMeetingEvent.description
          .replace(/(?:\r\n|\r|\n)/g, '<br>')
          .replace(/^(?:<br>)*/g, '');

        let doc = new DOMParser().parseFromString(htmlNewLines, 'text/html');
        let allLinks = doc.querySelectorAll('a');
        if (allLinks.length === 0) {
          // no links found, try linkify text
          const html = linkify(htmlNewLines);
          doc = new DOMParser().parseFromString(html, 'text/html');
        } else {
          allLinks.forEach((a) => a.setAttribute('target', '_blank'));
        }

        return doc.body.innerHTML;
      }

      return '';
    },
    expandedLocation() {
      let conference = '';
      const entryPoints = this.activeMeetingEvent?.conferenceData?.entryPoints;
      if (entryPoints) {
        for (const entry of entryPoints) {
          if (entry.entryPointType == 'video') {
            conference = entry.uri;
            break;
          }
        }
      }

      let location = this.activeMeetingEvent.location || '';
      if (conference && location) {
        return `${location} ${conference}`;
      } else if (conference) {
        return conference;
      }

      return location;
    },
    isAuthenticated() {
      // return true;
      return this.$gAuth?.isAuthorized;
    },
    myselfOnlyAttendee() {
      return this.getMyEmail ? [{ email: this.getMyEmail }] : [];
    },
    getMyEmail() {
      return this.$gAuth?.basicProfile?.getEmail();
    },
  },
  methods: {
    onInsertActions({ actionsHTML, actions }) {
      // wrap actions to list
      // remove reminddate if remind==true cause editor set reminder after insertion
      if (!Array.isArray(actionsHTML)) return;

      const html = actionsHTML.join('');
      let doc = new DOMParser().parseFromString(html, 'text/html');
      const actionItems = doc.querySelectorAll('div[data-type="action_item"]');
      let resultInnerHTML = '';
      for (const ai of actionItems) {
        if (ai.dataset['remind'] === 'true') {
          delete ai.dataset['reminddate'];
        }
        resultInnerHTML += `<li>${ai.outerHTML}</li>`;
      }

      this.htmlToInsert = `<ul>${resultInnerHTML}</ul>`;

      this.isPrivate
        ? this.addHTMLToNotesPrivate++
        : this.addHTMLToNotesPublic++;

      this.openLeftovers = false;
      this.$emit('removeActions', { actions, isPrivate: this.isPrivate });
    },
    onOpenLeftovers() {
      this.$emit('getleftovers');
      this.openLeftovers = !this.openLeftovers;
    },
    onQuickMenuClick({ type }) {
      switch (type) {
        case ItemType.SHOW_MORE:
          this.openAgenda = true;
          break;
        case ItemType.BRAINSTORMING:
          this.autoInsertId = 'template1';
          this.autoInserCounter++;
          break;
        case ItemType.STANDUP:
          this.autoInsertId = 'template2';
          this.autoInserCounter++;
          break;
        case ItemType.ONE_ON_ONE:
          this.autoInsertId = 'template3';
          this.autoInserCounter++;
          break;
        case ItemType.CALENDAR_DESC:
          this.autoInsertId = 'event';
          this.autoInserCounter++;
          break;
        case ItemType.LAST_AGENDA:
          if (this.isPrivate) {
            this.$emit('refreshLastMeetingNotesPrivate');
            this.htmlToInsert =
              this.lastMeetingNotesPrivate?.agenda_items || '';
            this.addHTMLToNotesPrivate++;
          } else {
            this.$emit('refreshLastMeetingNotesPublic');
            this.htmlToInsert = this.lastMeetingNotesPublic?.agenda_items || '';
            this.addHTMLToNotesPublic++;
          }
          break;
        case ItemType.LAST_FULL:
          if (this.isPrivate) {
            this.$emit('refreshLastMeetingNotesPrivate');
            this.htmlToInsert = this.lastMeetingNotesPrivate?.notes || '';
            this.addHTMLToNotesPrivate++;
          } else {
            this.$emit('refreshLastMeetingNotesPublic');
            this.htmlToInsert = this.lastMeetingNotesPublic?.notes || '';
            this.addHTMLToNotesPublic++;
          }
          break;
      }
    },

    onNoteHTMLPublic({ notes, agenda_items }) {
      // open actual modal
      this.noteHTMLPublic = notes;
      this.agendaItemsHTMLPublic = agenda_items;
      this.openEndMeetingModalPublic = true;
    },
    onNoteHTMLPrivate({ notes, agenda_items }) {
      // open actual modal
      this.noteHTMLPrivate = notes;
      this.agendaItemsHTMLPrivate = agenda_items;
      this.openEndMeetingModalPrivate = true;
    },

    checkAccess() {
      if (!this.calendarId || !this.meetingId) return;

      checkMeetingAccess(this.calendarId, this.meetingId).then((r) => {
        if (r.error) {
          this.editorACL = {
            principal_id: '',
            level: 'NONE',
            type: 'NONE',
          };
        } else {
          this.editorACL = r;
        }

        this.changeEditorEnabled(this.editorACL);
      });
    },

    onTitleEnterEsc(e) {
      e.target.blur();
    },
    onTitleBlur(e) {
      if (!this.titlePendingChange) return;

      const oldText = this.activeMeetingEvent.summary || '(No title)';
      const newText = e.target?.innerText.trim().replace(/\n|\r/g, '') || '✍️';

      // remove formatting and keep jsut text
      document.getElementById('active-meeting-title').innerHTML = '';
      document.getElementById('active-meeting-title').innerText = newText;
      if (newText != oldText) {
        this.$emit('patchMeetingTitle', newText);
      }
    },
    onTitleClick() {
      if (!this.isTitleEditable) {
        this.$snack('⚠️ Can’t change the name of your calendar events');
      }
    },
    openRecapModal(modalType) {
      this.modalType = modalType;
      // do not open, increment counter to get notes from editor and open after you get notes
      if (this.isPrivate) {
        this.getNotePrivate++;
      } else {
        this.getNotePublic++;
      }
    },
    async isPrivateToggle(val) {
      // TEMP workaround to see the toggle animation
      setTimeout(() => {
        this.$emit('toggleprivate', val);
      }, 200);
    },

    onScroll() {
      const hideOnScrollElems = document.querySelectorAll('.hide-on-scroll');

      if (this.isMobile && hideOnScrollElems.length > 0) {
        clearTimeout(this.scrollTimer);
        hideOnScrollElems.forEach((elem) => {
          elem.style.display = 'none';
        });

        this.scrollTimer = setTimeout(() => {
          hideOnScrollElems.forEach((elem) => {
            elem.style.display = 'block';
          });
        }, 300);
      }
    },
    changeEditorEnabled(val) {
      if (!val) return; // val==null before fetch

      const { level, type, wm_count, new_note } = val;
      // console.log('level, type', level, type, wm_count, new_note);

      if (ACCESS_LEVEL[level] == ACCESS_LEVEL.NONE) {
        return;
      } else if (ACCESS_LEVEL[level] == ACCESS_LEVEL.WRITE)
        this.editorReadOnly = false;
      else if (ACCESS_LEVEL[level] == ACCESS_LEVEL.READ)
        this.editorReadOnly = true;

      if (this.editorReadOnly && this.activeMeetingEvent) {
        var message = 'Need to edit the notes? Poke host for access.';
        var errorMsg = 'readonly-signedin';
        if (this.isAuthenticated) {
          if (ACCESS_TYPE[type] == ACCESS_TYPE.ACCOUNT_LIMIT) {
            message = [
              { text: 'You have reached your weekly note limit (5 out of 5)' },
              { link: { text: 'Get unlimited', to: 'profile' } },
            ];
            errorMsg = 'readonly-accountlimit';
          }
        } else {
          message = [
            { text: 'Need to edit the notes? ' },
            { link: { text: 'Sign in to Meetric.', to: 'signin' } },
          ];
          errorMsg = 'readonly-signedout';
        }
        this.$notification(message, {
          closable: false,
          type: 'warning',
          errorMsg,
        });

        return;
      }

      if (!this.editorReadOnly && this.activeMeetingEvent) {
        if (!this.isAuthenticated) {
          this.$notification(
            [
              { link: { text: 'Sign in to Meetric', to: 'signin' } },
              { text: ' to track all your meeting notes in one place.' },
            ],
            {
              closable: false,
              type: 'warning',
              errorMsg: 'public-signedout',
            }
          );
          return;
        }

        const plan = this.$store.getters['plan'];
        if (plan == 0 && new_note && wm_count == 4) {
          this.remainingBarShown = true;
          this.$notification(
            [
              { text: `1 meeting note left this week` },
              { link: { text: 'Get unlimited', to: 'profile' } },
            ],
            {
              closable: true,
              type: 'warning',
              errorMsg: `1 note remaining`,
            }
          );
        }
      }
    },
    updateParticipants(participants) {
      // consider only unique ones
      if (!participants) return;
      this.onlineParticipants = [...new Set(participants.map((p) => p.email))];
    },
    isMeetingOver(isOver) {
      console.log(isOver);
      this.meetingOverStatus = isOver;
    },
    onAgendaToNotes({ html, name, custom }) {
      this.htmlToInsert = html; // agenda or template
      if (this.isPrivate) this.addHTMLToNotesPrivate++;
      else this.addHTMLToNotesPublic++;

      const props = {
        meeting_id: this.activeMeetingEvent.id,
        inRelatedMeeting: false,
        isPrivate: this.isPrivate,
        name: name,
        custom: custom,
        // custom TODQ
      };
      window.meetric.track('Add agenda', props);
    },

    onOnlyActionsClick() {
      this.showOnlyActions = !this.showOnlyActions;

      const props = {
        meeting_id: this.activeMeetingEvent.id,
        inRelatedMeeting: false,
        isPrivate: this.isPrivate,
        value: 'filterActionsClick',
      };
      window.meetric.track('Meeting interaction', props);
    },
    onOnlyNotesClick() {
      this.showOnlyActions = !this.showOnlyActions;

      const props = {
        meeting_id: this.activeMeetingEvent.id,
        inRelatedMeeting: false,
        isPrivate: this.isPrivate,
        value: 'filterNotesClick',
      };
      window.meetric.track('Meeting interaction', props);
    },
    getDateOrDateTime(item) {
      if (item) {
        return item.dateTime ? item.dateTime : item.date;
      }
      return null;
    },
    onEditorUpdate() {
      // console.log('updated');

      if (!this.remainingBarShown) return;
      // console.log('try to hide bar');
      this.$notification('');
      this.remainingBarShown = false;
    },

    onEditorCount(count) {
      this.editorActionsTotal = count.total;
      this.editorActionsCompleted = count.completed;
      this.editorHasNotes = count.notes > 0;
    },
    onEditorCountPrivate(count) {
      this.editorActionsTotalPrivate = count.total;
      this.editorActionsCompletedPrivate = count.completed;
      this.editorHasNotesPrivate = count.notes > 0;
    },
    showLocationModal(event) {
      this.locationText = event;
      this.openLoc = !this.openLoc;
    },
    recapSent(data) {
      const {
        end,
        recapOn,
        reminderOn,
        reminderWas,
        autocancelOn,
        autocancelWas,
        slackOn,
        eventDescOn,
        notionOn,
        recipientsCount,
        reminderCount,
        sharePageText,
      } = data;

      let remindTurnedOnWithCount =
        reminderOn && reminderCount > 0 && reminderOn != reminderWas;
      let remindTurnedOffWithCount =
        !reminderOn && reminderCount > 0 && reminderOn != reminderWas;

      let autocancelTurnedOn = autocancelOn && autocancelOn != autocancelWas;
      let autocancelTurnedOff = !autocancelOn && autocancelOn != autocancelWas;

      let reminderNoChance = reminderOn == reminderWas;
      let autocancelNoChance = autocancelOn == autocancelWas;

      if (this.modalType == 'url') {
        // sharing with or without reminder set
        if (!this.isPubliclyShared && sharePageText == '') {
          // was not shared and is not shared now

          this.openEndMeetingModalPublic = false;
          this.openEndMeetingModalPrivate = false;
          return;
        } else {
          let shareText = '';
          if (sharePageText == '')
            shareText = 'This page is not public anymore';
          else shareText = sharePageText;

          let text = `${shareText}`;
          this.$snack(text);
        }

        this.openEndMeetingModalPublic = false;
        this.openEndMeetingModalPrivate = false;
        return;
      }
      if (this.modalType == 'note') {
        const channels = [];
        if (recapOn && recipientsCount > 0) channels.push('email');
        if (slackOn) channels.push('Slack');
        if (notionOn) channels.push('Notion');
        if (eventDescOn) channels.push('calendar');

        if (channels.length > 0) {
          // at least 1 channel selected
          let text = '';
          text = `Full ${this.isPrivate ? 'private' : 'shared'} notes sent`;

          this.$snack(text);
        }
      }
      if (this.modalType == 'remind') {
        if (remindTurnedOnWithCount && autocancelTurnedOn) {
          this.$snack(
            'Meeting reminder scheduled (email) + auto-cancel enabled'
          );
        } else if (remindTurnedOffWithCount && autocancelTurnedOff) {
          this.$snack(
            'Meeting reminder disabled (email) + auto-cancel disabled'
          );
        } else if (remindTurnedOnWithCount && autocancelTurnedOff) {
          this.$snack(
            'Meeting reminder scheduled (email) + auto-cancel disabled'
          );
        } else if (remindTurnedOffWithCount && autocancelTurnedOn) {
          this.$snack(
            'Meeting reminder disabled (email) + auto-cancel enabled'
          );
        } else if (remindTurnedOffWithCount && autocancelNoChance) {
          this.$snack('Meeting reminder disabled (email)');
        } else if (remindTurnedOnWithCount && autocancelNoChance) {
          this.$snack('Meeting reminder scheduled (email)');
        } else if (autocancelTurnedOn && reminderNoChance) {
          this.$snack('Auto-cancel enabled');
        } else if (autocancelTurnedOff && reminderNoChance) {
          this.$snack('Auto-cancel disabled');
        }
      }

      if (end.status == 'early') {
        this.rewardMinutesEarly = end.minutes;
        this.showReward = true;
        setTimeout(() => {
          // delayed so it closes after reward is showed
          this.openEndMeetingModalPublic = false;
          this.openEndMeetingModalPrivate = false;
        }, 750);
      } else {
        this.openEndMeetingModalPublic = false;
        this.openEndMeetingModalPrivate = false;
        party.screen();
      }
    },
    focusOnEditor() {
      // console.log('focusonedit');
      // Check if the contenteditable was clicked directly (i.e. don't move the cursor to the end)
      if (!document.activeElement.isContentEditable) {
        const editor = document.getElementById('meetriceditor');
        if (editor) {
          const divContentEditable = editor.childNodes[0];
          if (divContentEditable) {
            // Move the cursor to the end: https://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity/3866442#3866442
            const range = document.createRange(); //Create a range (a range is a like the selection but invisible)
            range.selectNodeContents(divContentEditable); //Select the entire contents of the element with the range
            range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
            const selection = window.getSelection(); //get the selection object (allows you to change selection)
            selection.removeAllRanges(); //remove any selections already made
            selection.addRange(range); //make the range you have just created the visible selection
          }
        }
      }
    },
  },
};
</script>

<style>
.quickmenu-loader-first {
  margin-top: 1.3rem;
}
@screen lg {
  .quickmenu-loader-first {
    margin-top: 1.15rem;
  }
}
.biggerEditor {
  min-height: 67vh;
}
.biggerEditor.mobile {
  min-height: 50vh;
}
</style>
<style scoped>
h3[contenteditable='true'] {
  min-height: 1.125rem;
  min-width: 1.125rem;
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
  outline: none;
}
h3[contenteditable='true']#active-meeting-title:empty:before {
  content: ' ';
  white-space: pre;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.saving-loader:after {
  content: '';
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis-dot 3s linear 0s infinite alternate;
}

@keyframes ellipsis-dot {
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}
</style>
